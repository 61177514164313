"use client";
import { ReactNode, useEffect, useState } from "react";

export type ClientOnlyProps = { children: ReactNode };

/**
 * Forces a component/its children to not render until the client loads 100%.
 * @param children
 * @constructor
 */
export function ClientOnly({ children }: ClientOnlyProps): ReactNode {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    /* if we don't do this we are warned that the prerender doesn't match the result:
      'Text content does not match server-rendered HTML' (also window isn't defined)
     */
    setIsClient(true);
  }, []);

  return isClient ? <>{children}</> : <></>;
}
